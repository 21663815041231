export const VideoData = [
    {
        id: 1,
        title: 'BCBGMAXAZRIA - Fall 2018',
        type: 'Fashion Film',
        category: 'commercial',
        directedBy: true,
        producedBy: true,
        editedBy: true,
        shotBy: true,
        yearCreated: '2018',
        runtime: 1,
        urlLink: 'https://player.vimeo.com/video/282912409',
        widescreen: true,
        coverImg: 'BCBGF2018-cover.jpg',
        notes: ''
    },
    {
        id: 44,
        title: 'Cruelty Free',
        type: 'Sizzle Reel',
        category: 'commercial',
        directedBy: true,
        producedBy: true,
        editedBy: true,
        shotBy: false,
        yearCreated: '2018',
        runtime: 1,
        urlLink: 'https://player.vimeo.com/video/346442732',
        widescreen: true,
        coverImg: 'CF-cover.jpg',
        notes: 'For Unilever'
    },
    {
        id: 3,
        title: 'BCBGeneration - Spring 2018',
        type: 'Fashion Film',
        category: 'commercial',
        directedBy: true,
        producedBy: true,
        editedBy: true,
        shotBy: true,
        yearCreated: '2017',
        runtime: 1,
        urlLink: 'https://www.youtube.com/embed/eBLfnVcpeGM',
        widescreen: false,
        coverImg: 'BCBGenSpr2018-cover.jpg',
        notes: ''
    },
    {
        id: 41,
        title: 'Stagecoach Festival Promo',
        type: 'Promo',
        category: 'commercial',
        directedBy: true,
        producedBy: true,
        editedBy: false,
        shotBy: true,
        yearCreated: '2017',
        runtime: 1,
        urlLink: 'https://player.vimeo.com/video/346441770',
        widescreen: false,
        coverImg: 'Stagecoach-cover.jpg',
        notes: 'For Golden Voice'
    },
    {
        id: 5,
        title: '3OH!3: BASMF',
        type: 'Music Video',
        category: 'commercial',
        directedBy: true,
        producedBy: true,
        editedBy: true,
        shotBy: true,
        yearCreated: '2016',
        runtime: 3,
        urlLink: 'https://www.youtube.com/embed/lyq9WP-Y_fk',
        widescreen: false,
        coverImg: 'Basmf-cover.jpg',
        notes: 'For Warner Records'
    },
    {
        id: 6,
        title: 'Current Sea',
        type: 'Feature',
        category: 'film',
        directedBy: true,
        producedBy: true,
        editedBy: true,
        shotBy: true,
        yearCreated: '2019',
        runtime: 90,
        urlLink: 'https://player.vimeo.com/video/391160371',
        widescreen: false,
        coverImg: 'CurSea-cover.jpg',
        notes: 'Premiering Fall 2019'
    },
    {
        id: 7,
        title: 'The 10th Element of Hip Hop',
        type: 'Short',
        category: 'film',
        directedBy: false,
        producedBy: false,
        editedBy: true,
        shotBy: false,
        yearCreated: '2016',
        runtime: 13,
        urlLink: 'https://player.vimeo.com/video/166108392',
        coverImg: '10th-cover.jpg',
        notes: 'Directed by Alison Klayman'
    },
    {
        id: 8,
        title: 'Lululemon - Brand Doc',
        type: 'Short',
        category: 'film',
        directedBy: false,
        producedBy: false,
        editedBy: true,
        shotBy: false,
        yearCreated: '2015',
        runtime: 13,
        urlLink: 'https://www.youtube.com/embed/aSFrX1wWugA',
        coverImg: 'lul-cover.jpg',
        notes: 'Directed by Alison Klayman'
    },
    {
        id: 9,
        title: 'TINY: A Story About Living Small',
        type: 'Feature',
        category: 'film',
        directedBy: true,
        producedBy: true,
        editedBy: true,
        shotBy: true,
        yearCreated: '2013',
        runtime: 62,
        urlLink: 'https://player.vimeo.com/video/28422870',
        coverImg: 'Tiny-cover.jpg',
        notes: 'SXSW, Hulu, Netflix, Al Jazeera'
    },
    {
        id: 10,
        title: 'American ESPionage',
        type: 'Feature',
        category: 'film',
        directedBy: true,
        producedBy: true,
        editedBy: true,
        shotBy: true,
        yearCreated: '2021',
        runtime: 90,
        urlLink: 'https://player.vimeo.com/video/346442764',
        widescreen: false,
        coverImg: 'AE-cover.jpg',
        notes: 'In production'
    },
    {
        id: 11,
        title: 'Inside the Oregon Standoff',
        type: 'Short',
        category: 'film',
        directedBy: false,
        producedBy: false,
        editedBy: true,
        shotBy: false,
        yearCreated: '2018',
        runtime: 14,
        urlLink: 'https://uw-media.usatoday.com/embed/video/34649339?placement=snow-embed',
        widescreen: false,
        coverImg: 'Nomans-cover.jpg',
        notes: 'For USA Today and PBS'
    },
    {
        id: 12,
        title: 'BCBGMAXAZRIA - Spring 2018',
        type: 'Fashion Film',
        category: 'commercial',
        directedBy: true,
        producedBy: true,
        editedBy: true,
        shotBy: true,
        yearCreated: '2017',
        runtime: 1,
        urlLink: 'https://player.vimeo.com/video/346621403',
        widescreen: false,
        coverImg: 'BCBGSpr2018-cover.jpg',
        notes: ''
    },
    {
        id: 13,
        title: 'BCBGeneration - Fall 2018',
        type: 'Fashion Film',
        category: 'commercial',
        directedBy: true,
        producedBy: true,
        editedBy: true,
        shotBy: true,
        yearCreated: '2018',
        runtime: 1,
        urlLink: 'https://player.vimeo.com/video/346621405',
        widescreen: false,
        coverImg: 'BCBGenFall2018-cover.jpg',
        notes: ''
    },
    {
        id: 42,
        title: 'Save the Day - Idiots',
        type: 'Promo',
        category: 'commercial',
        directedBy: false,
        producedBy: false,
        editedBy: true,
        shotBy: false,
        yearCreated: '2016',
        runtime: 1,
        urlLink: 'https://www.youtube.com/embed/fwLbDGX4qbk',
        widescreen: false,
        coverImg: 'Idiots-cover.jpg',
        notes: 'Directed by Joss Whedon'
    },
    {
        id: 45,
        title: 'ESPN - Nacion Sizzle',
        type: 'Sizzle Reel',
        category: 'commercial',
        directedBy: false,
        producedBy: false,
        editedBy: true,
        shotBy: false,
        yearCreated: '2017',
        runtime: 1,
        urlLink: 'https://player.vimeo.com/video/346639616',
        widescreen: false,
        coverImg: 'Espn-cover.jpg',
        notes: 'For Upfronts'
    },
    {
        id: 17,
        title: 'Treehugger VR - Mini Doc',
        type: 'Short',
        category: 'film',
        directedBy: true,
        producedBy: false,
        editedBy: true,
        shotBy: false,
        yearCreated: '2017',
        runtime: 2,
        urlLink: 'https://player.vimeo.com/video/346442736',
        widescreen: false,
        coverImg: 'Treehugger-cover.jpg',
        notes: ''
    },
    {
        id: 49,
        title: 'CEQ - Pump Teaser',
        type: 'Sizzle Reel',
        category: 'commercial',
        directedBy: true,
        producedBy: false,
        editedBy: true,
        shotBy: false,
        yearCreated: '2017',
        runtime: 1,
        urlLink: 'https://player.vimeo.com/video/346442751',
        widescreen: false,
        coverImg: 'CEQ-Pump-cover.jpg',
        notes: ''
    },
    {
        id: 21,
        title: 'Romeo is Bleeding',
        type: 'Feature',
        category: 'film',
        directedBy: false,
        producedBy: false,
        editedBy: true,
        shotBy: false,
        yearCreated: '2015',
        runtime: 85,
        urlLink: 'https://www.youtube.com/embed/EE6m_05dQfw',
        widescreen: false,
        coverImg: 'Romeo-cover.jpg',
        notes: 'SFIFF, Netflix'
    },
    {
        id: 22,
        title: 'Alabama Bound',
        type: 'Feature',
        category: 'film',
        directedBy: false,
        producedBy: false,
        editedBy: true,
        shotBy: false,
        yearCreated: '2016',
        runtime: 88,
        urlLink: 'https://player.vimeo.com/video/194821856',
        widescreen: false,
        coverImg: 'alabama-cover.jpg',
        notes: 'Frameline'
    },
    {
        id: 43,
        title: 'Alabama Bound - Trailer',
        type: 'Trailer',
        category: 'commercial',
        directedBy: false,
        producedBy: false,
        editedBy: true,
        shotBy: false,
        yearCreated: '2016',
        runtime: 2,
        urlLink: 'https://player.vimeo.com/video/194821856',
        widescreen: false,
        coverImg: 'alabama-cover.jpg',
        notes: 'Frameline'
    },
    {
        id: 47,
        title: 'Current Sea - Sizzle Trailer',
        type: 'Trailer',
        category: 'comercial',
        directedBy: true,
        producedBy: true,
        editedBy: true,
        shotBy: true,
        yearCreated: '2019',
        runtime: 3,
        urlLink: 'https://player.vimeo.com/video/346448339',
        widescreen: false,
        coverImg: 'CurSea-cover.jpg',
        notes: 'Premiering Fall 2019'
    },
    {
        id: 46,
        title: 'Mr. Pig - Trailer',
        type: 'Trailer',
        category: 'commercial',
        directedBy: false,
        producedBy: false,
        editedBy: true,
        shotBy: false,
        yearCreated: '2019',
        runtime: 2,
        urlLink: 'https://www.youtube.com/embed/t8NA0TSehS4',
        widescreen: false,
        coverImg: 'Pig-cover.jpg',
        notes: 'Directed by Diego Luna'
    },
    {
        id: 47,
        title: 'Hourglass - Purpose',
        type: 'Branded Content',
        category: 'commercial',
        directedBy: true,
        producedBy: true,
        editedBy: true,
        shotBy: false,
        yearCreated: '2019',
        runtime: 1,
        urlLink: 'https://player.vimeo.com/video/360582369',
        widescreen: false,
        coverImg: 'HGPurpose-cover.jpg',
        notes: 'High post engagement'
    },
    {
        id: 14,
        title: 'Lululemon - Corporate Anthem',
        type: 'Branded Content',
        category: 'commercial',
        directedBy: false,
        producedBy: false,
        editedBy: true,
        shotBy: false,
        yearCreated: '2015',
        runtime: 1,
        urlLink: 'https://player.vimeo.com/video/346441594',
        widescreen: false,
        coverImg: 'LuluAnthem-cover.jpg',
        notes: 'Directed by Alison Klayman'
    },
    {
        id: 180,
        title: 'Ritual Chocolate',
        type: 'Branded Content',
        category: 'commercial',
        directedBy: true,
        producedBy: true,
        editedBy: true,
        shotBy: true,
        yearCreated: '2015',
        runtime: 2,
        urlLink: 'https://player.vimeo.com/video/159433607',
        widescreen: false,
        coverImg: 'Ritual-cover.jpg',
        notes: ''
    },
    {
        id: 181,
        title: 'Grounded Climate Academy - Mitigating Methane',
        type: 'Short',
        category: 'film',
        directedBy: false,
        producedBy: false,
        editedBy: true,
        shotBy: false,
        yearCreated: '2021',
        runtime: 2,
        urlLink: 'https://player.vimeo.com/video/649686682',
        widescreen: false,
        coverImg: 'ClimateAcademy-cover.jpg',
        notes: ''
    },
    {
        id: 200,
        title: 'CEQ - Recap',
        type: 'Branded Content',
        category: 'commercial',
        directedBy: true,
        producedBy: false,
        editedBy: true,
        shotBy: false,
        yearCreated: '2017',
        runtime: 2,
        urlLink: 'https://player.vimeo.com/video/346442743',
        widescreen: false,
        coverImg: 'CEQ-Recap-cover.jpg',
        notes: ''
    }
]
